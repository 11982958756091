import React from "react";

const Page404 = () => {
  return (
    <section
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Not Found</h1>
    </section>
  );
};

export default Page404;
